import { FaGithub } from "react-icons/fa";

const extras = [
  {
    id: 1,
    name: "Mapty",
    project: "HTML, CSS, JavaScript",
    image: "./forkifyIcon.png",
    text: "This project simulates a workout application where workouts and their data can be pinned to an interactive map using GeoLocation by way of a 3rd party library. ",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/maptyApp",
  },
  {
    id: 2,
    name: "Git Search",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a user search for GitHub accounts which pulls up members data, including some of their main works and bio's, through utilization of the GitHub API.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/gitHubProfilesApp",
  },
  {
    id: 3,
    name: "Login",
    project: "HTML, CSS, React",
    image: "",
    text: "This login component presents a register or a login form for users. It can easily be styled and cross-utilized to fit a wide array of use cases with minimal edits required.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactLoginScreenComponent2",
  },
  {
    id: 4,
    name: "Weather App",
    project: "HTML, SASS, JavaScript",
    image: "",
    text: "This project utilizes a public API to fetch weather data for user specified locations anywhere in the world. It can be cross utilized with ease and is set up for mobile.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/weatherApp",
  },
  {
    id: 5,
    name: "Slide Box",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This advanced sliding UI component with interactivity can be utilized across pages and sites as needed with changes and edits done per the instance.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/slider1",
  },
  {
    id: 6,
    name: "Etch~n~Sketch",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This interactive entertainment app is modeled after the old analagous children's toy of the same name.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/etchAndSketch",
  },
  {
    id: 7,
    name: "Bankist",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This is a banking application requiring user login. It displays the users accounts summary as well as carries out certain transactions.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/bankistApp",
  },
  {
    id: 8,
    name: "List App",
    project: "HTML, CSS, React",
    image: "",
    text: "This React list App comes replete with alerts, edit, delete and clear all buttons. It can be cross purposed and utilized with ease.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactListComponentDelux",
  },
  {
    id: 9,
    name: "Menu App",
    project: "HTML, CSS, React",
    image: "",
    text: "This React app presents menu items in card format. Data is dynamically rendered, including the buttons, depending on category type. This menu can be changed easily to display data of any type.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactMenuApp",
  },
  {
    id: 10,
    name: "Casio",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project is designed and modeled after an old school desktop calculator of the Casio variety. It was done as part of The Odin Project curriculum.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/calculatorProject",
  },
  {
    id: 11,
    name: "Day/Night",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a beautifully smooth toggle switch with icons which can switch between states. Easily reusable and transferable between projects.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/dayNightToggler",
  },
  {
    id: 12,
    name: "Dice Game",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "The project is a two-player dice game with a score limit to win. The game is fully functional and can be reset when required",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/DiceGame",
  },
  {
    id: 13,
    name: "Password Cr8r",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project takes in four parameters and uses them to generate random passwords. This is a stand alone project which can be cross utilized with ease.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/passwordGeneratorApp",
  },
  {
    id: 14,
    name: "Perspective",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates 3d background with layering by way of clip art and parallax scrolling. Includes a side navigation bar.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/perspective",
  },
  {
    id: 14,
    name: "Drawing App",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project allows for the creation of art on a digital canvas and was modeled after old desktop drawing apps.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/drawingApp",
  },
  {
    id: 15,
    name: "Countdown",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project uses built in JavaScript Date methods in order to create a countdown timer which can be set and adjusted with ease.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/countdownTimer",
  },
  {
    id: 16,
    name: "Google",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "My first coding project - kept for nostalgia. The recreation of Google's homepage via The Odin Project.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/google-homepage",
  },
  {
    id: 17,
    name: "Tick-Tock",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a simple clock which can be set to any time zone and can easily be cross-utilized as a page component.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/clock",
  },
  {
    id: 18,
    name: "Tick-Tock",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a simple clock which can be set to any time zone and can easily be cross-utilized as a page component.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/clock",
  },
  {
    id: 19,
    name: "The Library",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a simple library which keeps track of a user's reading and can add or delete books as desired.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/library",
  },
  {
    id: 20,
    name: "Drum Kit",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a drum kit which can be played using either the keys or by click event. Has 8 different programmed notes.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/drumKit",
  },
  {
    id: 21,
    name: "Just Breathe",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This simple JS project is for relaxation and breathing excercises. It can be utilized in a variety of ways and is also set up for mobile responsivness.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/relaxedBreathingCircle",
  },
  {
    id: 22,
    name: "Quiz App",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project made a quiz game which can be loaded with questions made up or imported. Scores are tracked and a winner announced.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/quizApp",
  },
  {
    id: 23,
    name: "Paper/Rock/Scissors",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This was another Odin Project which recreates the timeless game between friends or rivals. Scores are kept and a winner announced.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/rockPaperScissors",
  },
  {
    id: 24,
    name: "Notes",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates a stand alone note taking app which can perform the CRUD operations and save or pull data from local storage.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/notesApp",
  },
  {
    id: 25,
    name: "AJAX",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: " This project shows how to filter data from a JSON doc for user search requests - currently works for searching out specified locations but can be reconfigured with ease.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/ajax",
  },
  {
    id: 26,
    name: "Picture Edit",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project utilizes CSS variables and JavaScript to rerender an image with added properties in real time, such as blur or border.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/CSSVariablesWithJavascript",
  },
  {
    id: 27,
    name: "Movies App",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "An interactive movies app. This project also utilizes a movie API to search and render movie request with data.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/moviesApp",
  },
  {
    id: 28,
    name: "Recipes App",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This mobile first project utilizes an API to render user recipe search request as well as the relevant cooking information.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/recipeApp",
  },
  {
    id: 29,
    name: "Perspective",
    project: "HTML, CSS, JavaScript",
    image: "",
    text: "This project creates 3d background with layering by use of perspective. ALso includes a side navigation bar.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/perspective",
  },
  {
    id: 30,
    name: "Contacts List",
    project: "HTML, CSS, React",
    image: "",
    text: "This simple React app consist of a reuseable card component which can be easily utilized in certain situations where repetitive elements are present.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactContactList",
  },
  {
    id: 31,
    name: "Navbar",
    project: "HTML, CSS, React",
    image: "",
    text: "This React component consist of a responsive and automatically updating navbar which rerenders according to the screen width.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactNavbar",
  },
  {
    id: 32,
    name: "Color Shader",
    project: "HTML, CSS, React",
    image: "",
    text: "This React app takes in a user color and returns an assortment of that colors shades and tints. Also allows for C&P to the clipboard.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactColorShader",
  },
  {
    id: 33,
    name: "Forkify",
    project: "HTML, CSS, Javascript",
    image: "",
    text: "This project creates a recipe application which utilizes a custom API to pull recipe and cooking data for user search inputs",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/forkifyApp",
  },
  {
    id: 34,
    name: "Node PW Maker",
    project: "NodeJS",
    image: "",
    text: "This project utilized NodeJS to create a command line password generator which outputs a randomized password to certain specified settings. Passwords can also be copied to the clipboard and saved into a .txt file.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/passgen",
  },
  {
    id: 35,
    name: "React Dark Mode",
    project: "React",
    image: "",
    text: "This React app creates a toggle dark mode for specified pages using changes to CSS class properties.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactDarkMode",
  },
  {
    id: 36,
    name: "Photo Generator",
    project: "React",
    image: "",
    text: "This React app uses the unsplash API to retrieve infinite images on scroll after user search input & with clickable images.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactPhotoGenerator",
  },
  {
    id: 37,
    name: "Pagination",
    project: "React",
    image: "",
    text: "This React app takes a list of GitHub users and paginates them on the clientside.",
    label1: <FaGithub />,
    label2: "https://github.com/MagentaSpruce/reactPagination",
  },
];

export default extras;
